<template>
  <div>
    <el-form class="query-form" inline>
      <el-form-item label="姓名">
        <el-input v-model="query.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="query.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="search"
          @keydown.enter="search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="main-content">
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>
          <el-button type="primary" plain size="small" @click="setAdminRole"
            >批量设置角色</el-button
          >
          <el-button
            type="primary"
            plain
            size="small"
            @click="addDialogFlag = true"
            >新增人员</el-button
          >
        </div>
        <div
          style="
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <el-upload
            :action="this.$store.state.uploadUrl + '/excel/importA?type=0'"
            :limit="1"
            name="uploadFile"
            :on-success="uploadSuccess"
            :headers="headers"
            style="margin-right: 20px"
          >
            <el-button type="primary" size="small"
              ><i class="el-icon-upload"></i> 自有员工导入</el-button
            >
          </el-upload>
          <el-upload
            :action="this.$store.state.uploadUrl + '/excel/importA?type=1'"
            :limit="1"
            name="uploadFile"
            :on-success="uploadSuccess"
            :headers="headers"
            style="margin-right: 20px"
          >
            <el-button type="primary" size="small"
              ><i class="el-icon-upload"></i> 外聘员工导入</el-button
            >
          </el-upload>
          <el-upload
            :action="this.$store.state.uploadUrl + '/excel/importA?type=2'"
            :limit="1"
            name="uploadFile"
            :on-success="uploadSuccess"
            :headers="headers"
          >
            <el-button type="primary" size="small"
              ><i class="el-icon-upload"></i> 务工员工导入</el-button
            >
          </el-upload>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="mobile" label="电话"> </el-table-column>
        <!-- <el-table-column prop="depart" label="部门"> </el-table-column> -->
        <el-table-column prop="institution" label="机构"> </el-table-column>
        <el-table-column prop="country" label="国别"> </el-table-column>
        <el-table-column prop="project" label="项目"> </el-table-column>
        <el-table-column prop="alwaysCountry" label="驻地"> </el-table-column>
        <el-table-column prop="nationality" label="人员类型">
          <template slot-scope="scope">
            {{
              scope.row.type == 0
                ? "自有员工"
                : scope.row.type == 1
                ? "外聘员工"
                : "务工人员"
            }}
          </template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            {{ scope.row.role == 0 ? "普通用户" : "管理员" }}
          </template>
        </el-table-column>
        <el-table-column prop="reportTime" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="setAdmin(scope.row, 'single')"
              >设置角色</el-button
            >
            <el-button type="text" @click="editAdmin(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              style="color: red"
              @click="deleteAdmin(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :current-page="query.pn"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 管理员设置弹窗 -->
    <el-dialog title="设置角色" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="选择角色">
          <el-select
            v-model="role"
            placeholder="请选择角色"
            size="small"
            value-key="value"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in options"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限配置：" v-if="role == 1"></el-form-item>
        <el-tree
          :data="organizationTree"
          show-checkbox
          node-key="id"
          ref="tree"
          highlight-current
          @check="treeDataChange"
          v-if="role == 1"
        >
        </el-tree>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adminSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增人员弹窗 -->
    <el-dialog title="新增人员" :visible.sync="addDialogFlag" width="30%">
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="form.mobile" size="small"></el-input>
        </el-form-item>
        <el-form-item label="选择类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择类型"
            size="small"
            value-key="value"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in types"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构/国别/项目" prop="organization">
          <el-cascader
            :options="organizationTree"
            :props="{ checkStrictly: true }"
            v-model="form.organization"
            clearable
            @change="treeChange"
            size="small"
            ref="treeChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="addSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改人员弹窗 -->
    <el-dialog title="修改人员" :visible.sync="editDialogFlag" width="30%">
      <el-form ref="form" :model="currentPerson">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="currentPerson.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="选择类型" prop="type">
          <el-select
            v-model="currentPerson.type"
            placeholder="请选择类型"
            size="small"
            value-key="value"
            style="width: 100%"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in types"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构/国别/项目" prop="organization">
          <el-cascader
            :options="organizationTree"
            :props="{ checkStrictly: true }"
            v-model="currentOrganization"
            @change="currentTreeChange"
            size="small"
            style="width: 100%"
            ref="currentTree"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="常驻地">
          <el-select
            v-model="currentPerson.alwaysCountry"
            size="small"
            placeholder="请选择"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userList,
  syncUser,
  setAdminBatch,
  setAdmin,
  deleteAdmin,
  addAdmin,
} from "@/api/api";
import {
  epidemicList,
  epidemicTypes,
  nucleicList,
  vaccinesList,
} from "@/api/api";
import { EditSinglePerson } from "@/api/person/status";
import { Organization, CountryList } from "@/api/person/status";
import page from "@/mixins/page";

export default {
  data() {
    return {
      query: {
        name: null,
        mobile: null,
        ps: 10,
        pn: 1,
      },
      total: 0,
      tableData: [],
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      countries: [],
      dialogVisible: false,
      editDialogFlag: false,
      role: "",
      addDialogFlag: false,
      organization: [],
      options: [
        {
          value: 0,
          label: "普通用户",
        },
        {
          value: 1,
          label: "管理员",
        },
      ],
      types: [
        {
          value: 0,
          label: "自有员工",
        },
        {
          value: 1,
          label: "外聘员工",
        },
        {
          value: 2,
          label: "务工员工",
        },
      ],
      organizationTree: [],
      admins: [],
      form: {
        name: "",
        mobile: "",
        type: "",
        organization: "",
      },
      rules: {
        name: [{ required: true, message: "请输入人员姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入人员电话", trigger: "blur" },
          { min: 5, max: 16, message: "手机号格式错误", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择人员类型" }],
        organization: [{ required: true, message: "请选择机构/国别/项目" }],
      },
      currentPerson: {},
      currentOrganization: [],
      treeArr: [],
    };
  },
  mixins: [page],
  mounted() {
    this.getCountries();
    this.getList();
    this.getOrganization();
  },
  methods: {
    async getCountries() {
      let res = await CountryList();
      this.countries = res.data.data;
    },
    treeChange(e) {
      let nodes = this.$refs.treeChange.getCheckedNodes();
      let type = nodes[0].data.type;
       this.form.project = ""
       this.form.country=""
       this.form.institution=""
      if (type == 2) {
        this.form.project = nodes[0].value;
      } else if (type == 1) {
        this.form.country = nodes[0].value;
      } else {
        this.form.institution = nodes[0].value;
      }
    },
    currentTreeChange(e) {
      let nodes = this.$refs.currentTree.getCheckedNodes();
      let type = nodes[0].data.type;
      this.currentPerson.project=""
      this.currentPerson.country=""
      this.currentPerson.institution=""
      if (type == 2) {
        this.currentPerson.project = nodes[0].value;
      } else if (type == 1) {
        this.currentPerson.country = nodes[0].value;
      } else {
        this.currentPerson.institution = nodes[0].value;
      }
      console.log(this.currentPerson)
    },

    editAdmin(item) {
      this.currentOrganization = [
        item.institution,
        item.country,
        item.project,
      ].filter((item) => !!item);
      this.currentPerson = { ...item };
      this.editDialogFlag = true;
    },
    setTreeData(arr) {
      let result = [];
      arr.forEach((ele) => {
        let item = {
          label: ele.name,
          value: ele.name,
          type: ele.type,
          id: ele.id,
          parentId: ele.parentId,
        };
        if (ele.secOrganizes.length) {
          item.children = this.setTreeData(ele.secOrganizes);
        }
        result.push(item);
      });
      return result;
    },
    // 获取组织架构
    async getOrganization() {
      let res = await Organization();
      this.organizationTree = this.setTreeData(res.data.data);
    },
    getList() {
      userList(this.query).then((res) => {
        this.total = res.data.data.total;
        this.tableData = res.data.data.list;
      });
    },
    syncInfo() {
      syncUser().then((res) => {
        this.getList();
      });
    },
    uploadSuccess(res) {
      if (res.code == 1) {
        this.$message.success("上传成功");
        setTimeout(() => {
          this.$router.go(0);
        }, 2000);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSelectionChange(e) {
      this.admins = [];
      this.admins = e.map((item) => {
        return item.id;
      });
      this.setType = "batch";
    },
    setAdmin(obj, type) {
      this.role = obj.role;
      this.admins = [];
      this.admins.push(obj.id);
      this.setType = type;
      this.dialogVisible = true;
      setTimeout(() => {
        this.setPowerList(obj.power);
      }, 10);
    },
    setPowerList(arr) {
      this.treeArr = [];
      this.$refs.tree.setCheckedKeys(arr ? arr.split(",") : []);
      this.treeForEach(
        this.organizationTree,
        this.$refs.tree.getCheckedNodes() || ""
      );
    },
    async adminSubmit() {
      let res = null;
      let power = this.treeArr
        .map((item) => {
          return item.id;
        })
        .join(",");
      if (this.setType == "single") {
        let id = this.admins.join(",");
        res = await setAdmin(id, { role: this.role, power: power });
      } else {
        res = await setAdminBatch({
          role: this.role,
          ids: this.admins,
          power: power,
        });
      }
      this.$message.success("设置成功");
      this.reset();
      this.dialogVisible = false;
    },
    deleteAdmin(obj) {
      this.$confirm("确认删除", "提示").then((res) => {
        deleteAdmin(obj.id).then(() => {
          this.$message.success("删除成功");
          this.reset();
        });
      });
    },
    addSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          delete this.form.organization;
          addAdmin(this.form).then((res) => {
            this.$message.success("添加成功");
            this.form.name = "";
            this.form.mobile = "";
            this.reset();
            this.addDialogFlag = false;
            this.$store.dispatch("refresh");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setAdminRole() {
      if (this.admins.length) {
        this.dialogVisible = true;
      } else {
        this.$message.error("请选择成员");
      }
    },
    async editSubmit() {
      let res = await EditSinglePerson(this.currentPerson.mobile, {
        name: this.currentPerson.name,
        type: this.currentPerson.type,
        institution: this.currentPerson.institution,
        country: this.currentPerson.country,
        project: this.currentPerson.project,
        alwaysCountry: this.currentPerson.alwaysCountry,
        flag:1
      });
      this.$message.success("修改成功");
      this.editDialogFlag = false;
      this.getList();
    },
    treeDataChange(e, a) {
      let tree = this.$refs.tree;
      this.treeArr = [];
      this.treeForEach(this.organizationTree, tree.getCheckedNodes() || "");
      console.log(this.treeArr);
    },
    treeForEach(original, compared) {
      original.forEach((item) => {
        if (
          compared.some((child) => {
            return child.id == item.id;
          })
        ) {
          if (!this.treeArr.some((ele) => ele.id == item.id)) {
            this.treeArr.push(item);
          }
        } else {
          if (item.children && item.children.length) {
            this.treeForEach(item.children, compared);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background-color: #f2f2f2;
}
</style>